@import 'overlays.mix.less';
@import 'mod.mix.less';
@import 'posts.mix.less';

a {
	border: none;
	text-decoration: none !important;
	&.nope, &.nope:hover {
		color: inherit;
	}
	&.disabled {
		pointer-events: none;
		color: inherit;
	}
}

a, .svg-link {
	cursor: pointer;
}

.svg-link svg {
	fill: currentColor;
}
.svg-link svg line {
	stroke: currentColor;
}


.watcher-toggle.enabled {
	border: solid 1px currentColor;
}

article, aside {
	padding: 4px 10px;
	margin: 1px;
}

body {
	overflow-x: hidden;
	margin: 0;
	padding: 0;
}

#threads {
	flex: 1;
	padding: 3em 1.4em;
	outline: none;
	max-width: 100vw;
}

aside {
	display: table;
	&.disabled a {
		color: grey !important;
		cursor: default;
	}
}

.bold {
	font-weight: bold;
}

.mono {
	font-family: monospace;
}

textarea {
	resize: both;
	font-family: sans-serif;
	width: 100%;
}

iframe {
	background: white;
}

.full-width {
	width: 100%;
}

.close {
	font-weight: bold;
}

.popup-menu, .act, .svg-link {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

h1, h2 {
	text-align: center;
}

h3 {
	display: inline;
	font-size: inherit;
}

.hidden {
	display: none;
}
article.hidden {
	display: none !important;
}

.spaced {
	& > *:before {
		content: ' ';
	}
	& > .act:not(.expanded):before {
		content: " [";
	}
}

.margin-spaced > * + * {
	margin-left: 0.1em;
}

hr {
	border: none;
	clear: both;
}

.hover-reveal {
	& > span:last-child {
		display: none;
		user-select: initial;
	}
	&:hover > span:last-child {
		display: block;
		position: absolute;
		margin: 0;
		white-space: pre-wrap;
	}
}

.act {
	&:not(.expanded) {
		&:before {
			content: "[";
		}
		&:after {
			content: "]";
		}
	}
	& + & {
		margin-left: 0.1em;
	}
}

.hide-empty:empty {
	display: none;
}

.top-margin {
	margin-top: 1em;
}

#user-background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -100;
	video {
		height: auto;
		width: 100%;
	}
}

#mascot-image {
	position: fixed;
    bottom: 0;
    right: 0;
    z-index: -99;
	max-width:50%;
	max-height:50%;
	width: auto;
	height: auto;
}

#lock {
	margin-left: auto;
	padding-left: 15px;
}

#bottom:focus {
	outline: 0;
}

table {
	border-collapse:collapse;
	label {
		padding: 0.2em;
	}
}

td {
	padding: 0;
}

.flex {
	display: flex;
	flex-wrap: wrap;
}

.aside-container {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
}

#catalog-controls {
	margin-left: auto;
	margin-top: 10px;
}

.captcha-container {
	padding: 0.5em;
	&, noscript {
		display: grid;
		grid-template-rows: auto;
		grid-template-columns: 240px;
	}
	img {
		background: white;
	}
}

.flag {
	height: .8em;
	margin-left: .3em;
	border: 1px solid black;
	vertical-align: middle;
}

.fileinfo {
	margin-left: 1ex;
	&::before {
		content: "(";
	}
	&::after {
		content: ")";
	}
	& > span::empty {
		display: none;
	}
	& > span:not(:empty):not([hidden]) ~ span::before {
		content: ", ";
	}
	&  > span.media-artist:not(:empty) + span.media-title::before {
		content: "\00a0-\00a0";
	}
}

@media screen and (max-width: 700px) {
	body {
		margin: 1px 0;
	}
	figure {
		padding: 0;
	}
	img.expanded {
		margin: 0;
	}
	blockquote {
		margin: 0.5em 0 0.5em 0.5em;
		min-width: 15em;
	}
	#threads {
		padding: 3em 0.3em;
	}
	#thread-post-counters, #sync-counter, .image-banner {
		display: none;
	}
	#post-controls  {
		width: 100%;
	}
}

#noscript-overlay {
	font-size: 15vw;
	display: flex;
	& > * {
		margin: auto;
	}
}

@media (pointer: coarse) {
	#banner {
		&, a {
			font-size: 120%;
		}
		/*height: 1.5em;*/
	}
	.banner-float.svg-link  {
		svg {
			width: 1.2em;
			height: 1.2em;
		}
		font-size: 120%;
	}
}


/* dirty css fixes and hacks
TODO: refactoring
*/
#board-navigation {
	font-size: 20px;
	margin: 5px 10px;
}
.banner-float.svg-link svg {
    font-size: 20px;
    vertical-align: middle;
}
hr {
	border-width: 2px;
	margin: 20px 0px;
}

article .post-container blockquote {
	overflow-y: auto !important;
	overflow-x: hidden;
	padding-right: 16px;
}


.index-thread article .post-container blockquote {
	max-height: 600px !important;
}


#post-controls input[name="cancel"] {
	margin-left: 20px;
}


.deleted { display: inline-flex; opacity: 0.5; }
#catalog .deleted { background-color: #6F2900; }
.deleted * { opacity: 1; }
.deleted:hover { opacity: 1; }
/*header .mod-checkbox { float: left; }*/

#catalog .mod-checkbox {
    display: inline;
    display: block;
    position: absolute;
    margin: -5px;
    box-shadow: #000 0px 0px 4px;
}

#sync { font-size: 12px; }



#banner > span {
	margin: 5px 10px;
}


/* text input tuning */
#text-input {
	overflow: auto !important;
	max-height: 50vh !important;
	width: 100vh !important;
}



/* mobile fixs */
#board-navigation {
    overflow-x: auto;
    overflow-y: hidden;
}
figcaption.spaced > a[download] {
    word-break: break-all;
}

/*.mobile #text-input { max-height: 46vh !important; }*/

/*.mobile .index-thread,
.mobile article { display:block; }*/
.mobile #threads { padding: 0 !important; }

.mobile .reply-form:not(.preview) {position: relative; margin: 1em 0;}
.mobile .overlay-container { position: relative; }

.mobile article,
.mobile aside {
	margin: 0px;
}

@media (pointer: coarse) {
	.mobile #banner,
	.mobile #banner a {
			font-size: 100%;
	}
}

.thumb.youtube {
	display: block;
	width: 100%;
	object-fit: none;
	background: #CCC;
}
.thumb.youtube.hide {
	display: none;
}

img.thumb:before,
img.thumb:after{
	content: " ";
	display: block;
	position: absolute;
}

.watcher-toggle {
	margin-left: 10px;
	display: inline-block;
	vertical-align: text-top;
}
.watcher-toggle::before {
	content: "";
}

.post-moderation-block {
	overflow-y: auto;
	max-height: 68px;
}
.mobile .post-moderation-block {
	padding-left: 7px;
}

#new-reply-form {
	margin-top: 30px;
}

.by-user .deleted-toggle {
	filter: invert(1);
}

.mobile .deleted {
	opacity: .8;
}

.mobile .index-thread.deleted hr {
	display: none;
}

/* pages */
aside.glass.spaced {
	overflow-x: auto;
	width: 100%;
	display: block;
	max-height: 32px;
	white-space: nowrap;
	margin-top: 10px;
}
aside.glass.spaced * {
	margin: 4px;
}
.mobile aside.glass.spaced * {
	margin: 10px;
}
.mobile aside.glass.spaced {
	font-size: 16px;
}

/* captcha */
input.captchouli-width {
	height: 50px;
	font-size: 16px;
}

.mobile .warning-mod {
	display: block;
	margin: 10px;
}

/* no meguca stuff */
.snowflakes,
#user-background,
#overlay-container { transform: translateZ(0); }
.snowflakes_body { pointer-events: none; }
figcaption.spaced { overflow: auto; display: inline-block; }
.upload-container > span { display: inline-block; }
.upload-container > input { margin-bottom: 10px; }
#post-controls > input { margin-bottom: 10px; margin-right: 10px; }
.reply-form { z-index: 300 !important; }
.mainLink { font-size: 20px; margin: 5px 0px 0px 0px; }
@keyframes zoom {
	0% {transform: scale(1,1);}
	50% {transform: scale(1.2,1.2);}
	100% {transform: scale(1,1);}
}
.likes { display:inline; }
.mobile .likes {
	display: inline-block;
	margin: 0.3em 0.8em;
}
.likes button {
	border:0; background:none; text-shadow:0px 0px 3px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
	/*min-height: 20px;*/
}
.likes button:active { top:1px; left:2px; color:#FFF; position:relative; }
.likes button span { border-radius:5px; padding:0 3px; text-shadow:1px 1px 3px #000,1px 1px 3px #000,1px 1px 3px #000; }
.likes button.like span { border:1px solid #358e00; background:#196900; color:#60ff00; }
.likes button.dislike span { border:1px solid #8c5902; background:#693b00; color:#ffa700; }
.like { color:#60ff00 }
.dislike { color:#ffa700 }
.likes > button:active { animation: zoom 0.1s forwards; }
.likes > button:active span { font-size: 16px; }
